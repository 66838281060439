body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav {
  width: 100%;
}

nav a.disabled {
  margin-left: auto;
  /* width: 25%; */
}

.minWidth {
 min-width: 300px;
}

.navbar-light .navbar-nav .nav-link {
  color: white !important;
}


#tracker-header ul {
  align-content: center;
  align-items: center;
  counter-reset: stepCount;
  display: flex;
  justify-content: space-around;
  margin: 2vh auto 0vh;
  font-size: 1.3em;
  margin-bottom:30px;
  margin-top:30px;
}

#tracker-header li {
  background: lightgray;
  color: white;
  content: ' ';
  display: flex;
  flex-grow: 1;
  height: .3em;
  line-height: 1em;
  margin: 0;
  position: relative;
  text-align: right;
  z-index: -1;
}

#tracker-header li::before {
  color: white;
  background: lightgray;
  border-radius: 50%;
  counter-increment: stepCount;
  content: " ";
  height: 2em;
  left: -2em;
  line-height: 2em;
  position: absolute;
  text-align: center;
  top: -.85em;
  width: 2em;
}



#tracker-header li:last-child {
  flex-basis: 0;
  flex-grow: 0;
  flex-shrink: 1;
}

#tracker-header ul.highlight-active li.active::before {
  background: green;
  content: "\002713";
  color:white;
}

#tracker-header ul.highlight-active li.processing::before {
  background: lightgray;
  content: " ";
  color:white;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #377bb5;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

#tracker-header ul.highlight-active li.no-insurance::before {
  background: black;
  content: " ";
}

#tracker-header ul.highlight-active li.denied::before {
  background: #cc0000;
  content: "X";
  font-weight: bold;
}

/* --------------------------------- */

#tracker-header ul {

  display: flex;
  margin-left:-45px;
  font-size: 1.1em;
}

#tracker-header li {
  /* background: lightgray; */
  float:right;
  color: black;
  font-weight:bold;
  content: ' ';
  display: flex;
  flex-grow: 1;
  height: .3em;
  line-height: 1em;
  margin: 0;
  text-align: left;
  z-index: -1;
}


#tracker-header li:last-child {
  flex-basis: 0;
  flex-grow: 0;
  flex-shrink: 1;
}

#tracker-header ul.highlight-active li.active::before {
  background: green;
  color:white;
}

.declined-text {
  color: red;
}
.approved-text {
  color: green;
}

#tracker-header .btn {
	-moz-border-radius: 30px;
	-webkit-border-radius: 30px;
	border-radius: 30px;
}

#tracker-header  {
 min-height: 120px;
}

.request-table  {
 min-height: 120px;
 border: none;
}

.request-tr  {
 min-height: 160px
}

.loader {
  border: 12px solid #f3f3f3;
  border-radius: 50%;
  border-top: 12px solid #3498db;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}



.full-width {
  width: '1150px' !important;
}

.btn {
  border-radius: "5px" !important;
  border: 1px solid;
  border-width: 1px; border-color: rgb(160,160,255);
}
/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

